
import DaftarPSP from "@/components/1crm/siswa/daftar-psp.vue";
import ApiService from "@/core/services/ApiService";
import { Modal } from "bootstrap";
import { Field } from "vee-validate";
import { defineComponent } from "vue";
import JsonViewer from "vue-json-viewer";
// Import loading overlay
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";

// end import loading overlay

export default defineComponent({
  name: "History Detail Migrasi",
  components: {
    Field,
    DaftarPSP,
    Loading, //loading overlay
    JsonViewer,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      name: "",
      identityNumber: "",
      gender: "",
      tags: [],
      status: "",
      request_katalis: [],
      response_katalis: [],
      disableButton: false,
      tableData: [],
      processId: "",
      paymentId: "",
      schoolId: "",
      namasekolah: "",
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
    };
  },

  beforeMount() {
    this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
    if (!this.schoolId)
      this.schoolId = JSON.parse(
        localStorage.getItem("user_account")!
      ).schoolId;
    this.namasekolah = JSON.parse(localStorage.getItem("schoolName")!);
    this.processId = JSON.parse(localStorage.getItem("processId")!);
    this.paymentId = JSON.parse(localStorage.getItem("paymentId")!);
    this.getData(this.paging.size, this.paging.page);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      this.isLoading = true;

      const toast = useToast();

      ApiService.getWithoutSlug(
        "crmv2/main_student/student/office/log_migration/" +
          this.processId +
          "?page=" +
          page +
          "&size=" +
          size +
          "&sort=createTime&dir=1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    handleBlobResponse(blobFileData, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `History daftar Siswa ${this.namasekolah}.${extension}`
      ); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },

    refreshMigrasi() {
      this.isLoading = true;

      const toast = useToast();

      ApiService.postWithoutSlug(
        "crmv2/main_student/student/office/refresh_migration_psp?schoolId=" +
          this.schoolId +
          "&paymentId=" +
          this.paymentId +
          "&processId=" +
          this.processId
      )
        .then((res) => {
          toast.success("Data Siswa Berhasil Terdaftar di PSP");
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 304) {
            toast.success("Data Siswa Berhasil Terdaftar di PSP");
            this.isLoading = false;
          } else if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },

    detailHistoryResponse(item, index) {
      this.name = item.name;
      this.identityNumber = item.identityNumber;
      this.gender = item.request_katalis.gender;
      this.tags = item.request_katalis.tags;
      this.status = item.status;
      this.request_katalis = item.request_katalis;
      this.response_katalis = item.response_katalis;
      const modal = new Modal(
        document.getElementById("kt_modal_response_detail_history")
      );
      modal.show();
    },

    kembaliHistory() {
      this.getData(this.paging.size, this.paging.page);
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    download() {
      const toast = useToast();
      if (this.schoolId && this.processId) {
        this.isLoading = true;
        ApiService.getDownload(
          "crmv2/main_student/student/office/log_migration/download/history_migration.xlsx?schoolId=" +
            this.schoolId +
            "&processId=" +
            this.processId
        )
          .then((response) => {
            this.handleBlobResponse(response.data, "xlsx");
            toast.success("Sukses Unduh File");
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            toast.error("Gagal Unduh File");
            this.isLoading = false;
          });
      } else {
        toast.error("Pilih Company Dahulu dan Tanggal");
      }
    },
  },
});
